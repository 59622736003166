<template>
  <!--<div class="max-w-screen-xl mx-auto px-5 bg-white min-h-sceen">
	<div>
        
	</div>
</div>
-->
  <div class="py-5 divide-y">
    <details class="group border-b-2 border-dashed">
      <summary
        class="flex justify-between items-center font-medium cursor-pointer list-none border-b-2 border-dashed"
      >
        <span class="text-lg font-bold text-main-purple-500 my-4">
          {{ faqQuestion }}</span
        >
        <span class="transition group-open:rotate-180">
          <svg
            fill="none"
            height="24"
            shape-rendering="geometricPrecision"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M6 9l6 6 6-6"></path>
          </svg>
        </span>
      </summary>
      <p class="font-regular text-lg my-4 group-open:animate-fadeIn">
        <slot />
      </p>
    </details>
  </div>
</template>

<script>
export default {
  props: {
    faqQuestion: String,
  },
};
</script>
