<template>
    <div class="px-2 my-3">
        <div>
            <h4 class="ml-2 text-main-pink-500 font-extrabold mb-2">Apps inclusos:</h4>
            <!-- <h4 class="text-center text-main-purple-500 font-extrabold mb-2">Apps inclusos:</h4> -->
        </div>
        <!-- <div>
            <ul class="list-image-[url(@/assets/img/check.png)] list-inside underline text-main-pink-500 font-medium">
                <li>Wi-fi Mesh</li>
                <li>MAX</li>
                <li>Paramount+</li>
                <li>Deezer</li>
                <li>Skeelo</li>
            </ul>
        </div> -->

        <a href="https://rnova.com.br/nova-sva" target="_blank">
            <div class="flex flex-row">
                <div v-if="idPlano == 20">
                    <img
                    class="m-2 md:my-0 w-10 md:w-8 h-auto"
                    src="@/assets/img/sva/hbo-max.png"
                    />
                </div>
                <div>
                    <img
                    class="m-2 md:my-0 w-10 md:w-8 h-auto"
                    src="@/assets/img/sva/mesh.png"
                    />
                </div>
                <div>
                    <img
                    class="m-2 md:my-0 w-10 md:w-8 h-auto"
                    src="@/assets/img/sva/nova-mais.png"
                    />
                </div>
                <div v-if="idPlano == 18 || idPlano == 23">
                    <img
                    class="m-2 md:my-0 w-10 md:w-8 h-auto"
                    src="@/assets/img/sva/deezer.png"
                    />
                </div>
                <div v-if="idPlano == 19 || idPlano == 21 || idPlano == 24">
                    <img
                    class="m-2 md:my-0 w-10 md:w-8 h-auto"
                    src="@/assets/img/sva/watch.png"
                    />
                </div>
                <div v-if="idPlano == 19 || idPlano == 21 || idPlano == 24">
                    <img
                    class="m-2 md:my-0 w-10 md:w-8 h-auto"
                    src="@/assets/img/sva/paramount.png"
                    />
                </div>
                <div>
                    <img
                    class="m-2 md:my-0 w-10 md:w-8 h-auto"
                    src="@/assets/img/sva/skeelo.png"
                    />
                </div>

            </div>
        </a>
    </div>

</template>
<script>
export default {
    name: "BoxSva",
    props: {
        svaPlano: Array,
        idPlano: Number
    },
    data() {
        return {
            testePorra: Array.isArray(this.svaPlano)
        }
    }
}
</script>