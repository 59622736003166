<template>
  <div class="about-us">
    <main
      class="relative min-h-screen flex flex-col justify-center overflow-hidden"
    >
      <div class="w-full mx-auto px-4 md:px-6 py-8 my-8">
        <div class="w-full max-w-7xl mx-auto mb-8">
          <h1
            class="font-black text-main-purple-500 text-6xl text-center tracking-tighter my-4 uppercase"
          >
            Trabalhe conosco
          </h1>
          <h3
            class="font-regular text-main-pink-500 text-2xl text-center leading-6"
          >
            Quer fazer parte da nossa equipe?<br /><strong>Então preencha os dados abaixo e
            envie seu currículo!</strong>
          </h3>
        </div>
        <return-box
          :return-status="`${this.status}`"
          :return-title="`${this.responseTitle}`"
          >{{ this.responseBody }}</return-box
        >
        <div class="grid grid-cols-1 sm:grid-cols-8 w=full max-w-7xl mx-auto">
          <div class="sm:col-span-3 sm:visible hide-on-mobile"><img src="@/assets/img/trabalhe-conosco.png" /></div>
          <div class="sm:col-span-5">
            <form @submit.prevent="this.sendContact()">
              <div class="w-full max-w-5xl mx-auto grid sm:grid-cols-2 gap-1">
                <div class="col-span-2 p-2">
                  <input
                    class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                    type="text"
                    placeholder="Digite seu nome"
                    name="clientName"
                    id="clientName"
                    v-model="clientName"
                    required

                  />
                </div>
                <div class="col-span-2 p-2">
                  <input
                    v-maska
                    data-maska="['(##)####-####', '(##)#####-####']"
                    class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                    type="text"
                    placeholder="Digite seu telefone"
                    name="clientPhone"
                    id="clientPhone"
                    v-model="clientPhone"
                    required

                  />
                </div>
                <div class="col-span-2 p-2">
                  <input
                    v-maska
                    data-maska="['###.###.###-##']"
                    class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                    type="text"
                    placeholder="Insira seu CPF"
                    name="clientDoc"
                    id="clientDoc"
                    v-model="clientDoc"
                    required

                  />
                </div>
                <div class="col-span-2 p-2">
                  <textarea
                    name="clientMessage"
                    id="clientMessage"
                    v-model="clientMessage"
                    placeholder="Digite aqui sua mensagem"
                    rows="4"
                    class="w-full form-textarea font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                    required
                  ></textarea>
                </div>
                <div class="col-span-2 p-2">
                  <div class="bg-main-purple-500 p-2 border-l-8 border-main-yellow-500 text-white md:text-lg rounded-2xl my-4" v-if="boxErrors">
                    <h3 class="text-main-yellow-500 text-xl font-bold mt-2 mb-4">Ops, ocorreu um erro</h3>
                    <ul class="list-inside list-disc mt-2 leading-5">
                      <li class="mb-2" v-if="mimeError">O arquivo precisa ser <strong>.doc, .docx ou .pdf</strong></li>
                      <li class="mb-2" v-if="sizeError">O arquivo precisa ser <strong>menor que 2mb</strong></li>
                    </ul>
                  </div>
                  <label class="text-xl font-extrabold text-main-purple-500 block mb-2" for="user_curriculum">Envie seu currículo</label>
                  <input
                    class="block w-full cursor-pointer bg-gray-50 border border-gray-300 text-gray-900 focus:outline-none focus:border-transparent text-sm rounded-lg" aria-describedby="user_curriculum_help"
                    name="selectFiles"
                    id="selectFiles"
                    @change="getClientCurriculum($event)"
                    type="file">
                  <div class="mt-1 text-sm text-gray-500" id="user_curriculum_help">Serão aceitos arquivos em <strong>.doc ou .pdf, com até 2mb</strong>.</div>
                </div>
                <div class="col-span-2 p-2">
                  <button
                    class="font-regular bg-main-purple-500 text-main-yellow-500 text-xl uppercase tracking-tight px-8 py-6 rounded-3xl my-4 mx-auto" v-if="sendMessage" 
                  >
                    Enviar <strong class="font-black">Mensagem</strong>
                  </button>
                  <button
                    class="font-regular bg-gray-400 text-white text-xl uppercase tracking-tight px-8 py-6 rounded-3xl my-4 mx-auto" v-else
                  >
                    Enviar <strong class="font-black">Mensagem</strong>
                  </button>
                </div>
              </div>
            </form>            
          </div>
        </div>

      </div>
    </main>
  </div>
</template>
<style scoped>
input[type=file]::-webkit-file-upload-button,
input[type=file]::file-selector-button {
  color:white;
  background-color:#fc3a69;
  padding: 1rem 2rem;
  font-size: large;
  font-weight: bold;
}
.hide-on-mobile {
  @media (orientation: portrait) {
    display: none;
  }
}
</style>
<script>
import ButtonLink from "@/components/layout/goods/ButtonLink.vue";
import ReturnBox from "@/components/layout/goods/ReturnBox.vue";
import JobForm from "@/mixins/JobForm";

let mimeType = ['application/msword','application/vnd.openxmlformats-officedocument.wordprocessingml.document','application/pdf'];
export default {
  name: "Segunda Via",
  components: {
    ButtonLink,
    ReturnBox
  },
  mixins: [JobForm],
  data: () => ({
    clientName: null,
    clientPhone: null,
    clientMessage: null,
    sendMessage: false,
    selectFiles: false,
    mimeError: false,
    sizeError: false,
    boxErrors: false,
    messageId: 4,
    titleSuccess: "Currículo enviado com sucesso!",
    messageSuccess:
      "Agradecemos o contato. Em breve, nossa equipe entrará em contato com você.",
    titleError: "Ops, algo deu errado...",
    messageError:
      "Nosso sistema não conseguiu enviar o currículo neste momento. Por favor, tente novamente.",

  }),
  methods: {
    getClientCurriculum(e) {
      let fileSize = Math.round(e.target.files[0].size / 1024);
      let fileMime = e.target.files[0].type;
      let mimeTest = mimeType.includes(fileMime);
      if (mimeTest && fileSize <= 2100) {
        this.selectFiles = e.target.files[0]
        this.sendMessage = true
        this.mimeError = false
        this.sizeError = false
        this.boxErrors = false

      } else {
        this.selectFiles = null
        this.sendMessage = false
        this.boxErrors = true
        if(!mimeTest) {
          this.mimeError = true
        } else {
          this.mimeError = false
        }
        if(fileSize > 2100) {
          this.sizeError = true
        } else {
          this.sizeError = false
        }

      }
    }
  }
};
</script>
