import axios from "axios";
import ReturnBox from "@/components/layout/goods/ReturnBox.vue";

export default {
  components: {
    ReturnBox,
  },
  data: () => ({
    clientName: null,
    clientDoc: null,
    clientPhone: null,
    clientPhoneAlt: null,
    clientEmail: null,
    clientAddress: null,
    errors: [],
    response: [],
    responseTitle: null,
    responseBody: null,
    fullPage: false,
    status: null,
  }),
  methods: {
    formatString(string) {
      return string.toString().replace(/[^a-z0-9]/gi, "");
    },

    sendContact() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        backgroundColor: "#FFC000",
        color: "#581E9B",
        opacity: 0.9,
      });
      let clientDoc = this.formatString(this.clientDoc);
      let clientPhone = this.formatString(this.clientPhone);
      let clientPhoneAlt = 0;

      if (this.clientPhoneAlt) {
        clientPhoneAlt = this.formatString(this.clientPhoneAlt);
      }

      axios
        .post(this.apiUrl + "/buy", {
          client_name: this.clientName,
          client_doc: clientDoc,
          client_phone: clientPhone,
          client_phone_2: clientPhoneAlt,
          client_email: this.clientEmail,
          client_address: this.clientAddress,
          plan: this.clientPlan,
        })
        .then((response) => {
          this.response = response.data;
          this.status = "success";
          this.responseTitle = this.titleSuccess;
          this.responseBody = this.messageSuccess;
        })
        .catch((errors) => {
          this.response = errors.response.data;
          this.status = "error";
          this.responseTitle = this.titleError;
          this.responseBody = this.messageError;
          // console.log(errors);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
