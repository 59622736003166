<template>
  <div>
    <section id="planos-index" class="my-6 flex justify-center items-center">
      <div class="py-8 rounded-md w-4/5 2xl:w-2/3">
        <home-plans :id-cidade="this.cityId" :id-tipo="this.typeId" />
      </div>
    </section>
    <box-indicacao />

  </div>
</template>
<script>
import BoxIndicacao from "@/components/layout/home/BoxIndicacao.vue";
import CitySelection from "@/mixins/CitySelection";
import HomePlans from '@/components/layout/HomePlans.vue';

export default {
  name: "HomeView",
  components: {
    BoxIndicacao,
    HomePlans,
  },
  data: () => ({
    cityId: null,
    typeId: null,
  }),
  mixins: [CitySelection],
  methods: {
    getParams() {
      var returnParam = {
        city: this.cityValue.showList.dataCity,
        type: this.cityValue.showList.dataType
      };
      return returnParam;
    }

  },
};
</script>