<template>
  <div class="about-us">
    <main
      class="relative min-h-screen flex flex-col justify-center overflow-hidden"
    >
      <div class="w-full mx-auto px-4 md:px-6 py-8 my-8">
        <div class="w-full max-w-4xl mx-auto mb-8">
          <h1
            class="font-black text-main-purple-500 text-7xl text-center tracking-tighter mt-4 mb-1 uppercase"
          >
            Renegociação?
          </h1>
          <h1
            class="font-black text-main-purple-500 text-5xl text-center tracking-tighter mt-1 mb-4"
          >
            A Nova liga para você!
          </h1>
          <h3
            class="font-regular text-main-pink-500 text-2xl text-center leading-6"
          >
            Precisa negociar uma fatura em aberto? Deixe seu nome e telefone.
            Entraremos em contato com você.
          </h3>
        </div>

        <return-box
          :return-status="`${this.status}`"
          :return-title="`${this.responseTitle}`"
          >{{ this.responseBody }}</return-box
        >

        <form @submit.prevent="this.sendContact()">
          <div class="w-full max-w-4xl mx-auto grid sm:grid-cols-2 gap-1">
            <div class="col-span-2 p-2">
              <input
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu nome"
                name="clientName"
                id="clientName"
                v-model="clientName"
                required
              />
            </div>
            <div class="p-2">
              <input
                v-maska
                data-maska="['###.###.###-##', '##.###.###/####-##']"
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu CPF ou CNPJ"
                name="clientDoc"
                id="clientDoc"
                v-model="clientDoc"
                required
              />
            </div>
            <div class="p-2">
              <input
                v-maska
                data-maska="['(##)####-####', '(##)#####-####']"
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu telefone"
                name="clientPhone"
                id="clientPhone"
                v-model="clientPhone"
                required
              />
            </div>
            <div class="col-span-2 p-2">
              <textarea
                name="clientMessage"
                id="clientMessage"
                placeholder="Digite aqui sua mensagem"
                rows="4"
                class="w-full form-textarea font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                v-model="clientMessage"
                required
              ></textarea>
            </div>
            <div class="p-2 mx-auto">
              <button
                class="font-regular bg-main-purple-500 text-main-yellow-500 text-xl uppercase tracking-tight px-8 py-6 rounded-3xl my-4 mx-auto"
              >
                Enviar <strong class="font-black">Mensagem</strong>
              </button>
            </div>
            <div class="p-2 mx-auto">
              <button-link
                link="https://api.whatsapp.com/send?phone=553136149300"
                button-color="bg-green-500"
                font-color="text-white"
                font-size="text-xl"
              >
                Chamar no <strong>WhatsApp</strong>
              </button-link>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import ContactForm from "@/mixins/ContactForm";
import ButtonLink from "@/components/layout/goods/ButtonLink.vue";

export default {
  name: "Renegociação",
  components: {
    ButtonLink,
  },
  mixins: [ContactForm],
  data: () => ({
    messageId: 3,
    titleSuccess: "Mensagem enviada com sucesso!",
    messageSuccess:
      "Agradecemos o contato. Em breve, nossa equipe entrará em contato com você.",
    titleError: "Ops, algo deu errado...",
    messageError:
      "Nosso sistema não conseguiu enviar a mensagem neste momento. Por favor, tente novamente.",
  }),
};
</script>
