<template>
  <div class="about-us">
    <main
      class="relative flex flex-col justify-center overflow-hidden"
    >
      <div class="w-full mx-auto px-4 md:px-6 py-8 my-8">
        <div class="w-full max-w-4xl mx-auto mb-4">
          <h1 class="font-black text-main-purple-500 text-6xl text-center my-4">
            Contratos e Regulamentos
          </h1>
          <h3
            class="font-regular text-main-pink-500 text-2xl text-center leading-6"
          >
            Acesse aqui os principais documentos da <strong>Nova</strong> e faça o download de forma simples, rápida e prática. 
          </h3>
        </div>
        <div class="flex flex-col justify-center">
          <div class="w-full max-w-4xl mx-auto">
            <contracts-regulations-box
              doc-title="Regulamento da promoção 'Indique um Amigo'"
              doc-file="indique-um-amigo-1.pdf"
            />
          </div>
          <div class="w-full max-w-4xl mx-auto">
            <contracts-regulations-box
              doc-title="Contrato de Comércio de Obras Digitais"
              doc-file="nova_sva_contrato_comercio_obras_digitais_skeelo.pdf"
            />
          </div>
          <div class="w-full max-w-4xl mx-auto">
            <contracts-regulations-box
              doc-title="Contrato de Prestação de Serviços de Valor Adicionado"
              doc-file="nova_sva_contrato_prestacao_servicos_valor_adicionado.pdf"
            />
          </div>
          <div class="w-full max-w-4xl mx-auto">
            <contracts-regulations-box
              doc-title="Contrato de Prestação de Serviços de Comunicação Multimídia"
              doc-file="nova_contrato_ppp_scm_internauta.pdf"
            />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ContractsRegulationsBox from '@/components/layout/goods/ContractsRegulationsBox.vue';
export default {
  name: "App",
  components: {
    ContractsRegulationsBox,
  },
};
</script>

FaqAccordion
