import { defineStore } from "pinia";

export const useCitySelectionStore = defineStore("citySelection", {
  //state - propriedades reativas
  state() {
    return {
      dataCity: 0,
      dataType: 0,
    };
  },
  //actions - methods
  actions: {
    save(dataCity, dataType) {
      this.dataCity = dataCity;
      this.dataType = dataType;
    },
    saveCity(dataCity) {
      this.dataCity = dataCity;
    },
    saveType(dataType) {
      this.dataType = dataType;
    },
  },
  //getters - computed
  getters: {
    showList() {
      return {
        dataCity: this.dataCity,
        dataType: this.dataType,
      };
    },
  },
});
