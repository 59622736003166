<template>
  <div></div>
</template>

<script>
import { BlipChat } from "blip-chat-widget";

export default {
  mounted() {
    new BlipChat()
      .withAppKey(
        "bm92YXRlbGVjb21yb3V0ZXI6NTQzZDk5NWEtMWVkMi00MDViLWFkYjEtZjRjNzEwNmEwOGNl"
      )
      .withButton({"color":"#581E9B","icon":"https://blipmediastore.blob.core.windows.net/public-medias/Media_740a0604-dcfc-45c9-88ff-d95e742056ee"})
      .withCustomCommonUrl("https://novatelecominternet.chat.blip.ai/")
      .build();
  },
};
</script>