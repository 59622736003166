<template>
  <div class="bg-gray-100 border-2 border-gray-200 p-4 rounded-xl my-4">
    <a :href="this.fileUrl" target="_blank">
        <div class="flex justify-center mt-2">
            <div>
                <BIconFileEarmarkArrowDownFill class="text-left text-main-pink-500 mr-2 text-xl" />
            </div>
            <div>
                <h2 class="text-main-purple-500 font-extrabold uppercase text-md tracking-wider">{{ docTitle }}</h2>
            </div>
        </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    docTitle: String,
    docFile: String,
  },
  data: () => ({
    fileUrl: null,
  }),
  methods: {
    getFileUrl() {
        this.fileUrl = 'assets/contracts/' + this.docFile
    }
  },
  mounted() {
    this.getFileUrl()
  }
};
</script>
