<template>
  <div class="about-us">
    <main
      class="relative min-h-screen flex flex-col justify-center overflow-hidden"
    >
      <div class="w-full mx-auto px-4 md:px-6 py-8 my-8">
        <div class="w-full max-w-4xl mx-auto mb-8">
          <h1
            class="font-black text-main-purple-500 text-6xl text-center tracking-tighter my-4"
          >
            Fale conosco
          </h1>
          <h3
            class="font-regular text-main-pink-500 text-2xl text-center leading-6"
          >
            Envie-nos uma mensagem!
          </h3>
          <div
            class="p-6 border-2 rounded-xl my-4 bg-main-purple-500 text-white border-l-8 border-l-main-yellow-500"
          >
            <h3
              class="font-extrabold text-main-yellow-500 text-xl mb-2 text-center"
            >
              Obs: Caso seja assinante precisam ser informados os dados do
              titular da linha.
            </h3>
          </div>
        </div>
        <form>
          <div class="w-full max-w-4xl mx-auto grid sm:grid-cols-2 gap-1">
            <div class="col-span-2 p-2">
              <input
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu nome"
                name="user_name"
                id="user_name"
              />
            </div>
            <div class="p-2">
              <input
                v-maska
                data-maska="['###.###.###-##', '##.###.###/####-##']"
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu CPF ou CNPJ"
                name="user_cpf"
                id="user_cpf"
              />
            </div>
            <div class="p-2">
              <input
                v-maska
                data-maska="['(##)####-####', '(##)#####-####']"
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu telefone"
                name="user_phone"
                id="user_phone"
              />
            </div>
            <div class="col-span-2 p-2">
              <textarea
                name="user_message"
                id="user_message"
                placeholder="Digite aqui sua mensagem"
                rows="4"
                class="w-full form-textarea font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
              ></textarea>
            </div>
            <div class="col-span-2 p-2">
              <button
                class="font-regular bg-main-purple-500 text-main-yellow-500 text-xl uppercase tracking-tight px-4 py-3 rounded-xl my-4 float-right"
              >
                Enviar <strong class="font-black">Mensagem</strong>
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  name: "Segunda Via",
};
</script>
