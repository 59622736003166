<template>
  <div class="bg-gray-100 border-2 border-gray-200 p-4 rounded-xl my-4">
    <h1
      class="text-main-purple-500 font-extrabold uppercase text-md tracking-wider"
    >
      {{ storeTitle }}
    </h1>
    <div class="flex justify-start mt-2">
      <div>
        <BIconGeoAltFill class="text-left mr-2 text-main-pink-500 text-xl" />
      </div>
      <div>
        <h2 class="text-left text-xl">{{ storeAddress }}</h2>
      </div>
    </div>
    <div class="flex justify-start mt-2">
      <div>
        <BIconStopwatch class="text-left text-main-pink-500 mr-2 text-xl" />
      </div>
      <div>
        <h2 class="text-left text-xl">{{ openingHours }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    storeTitle: String,
    storeAddress: String,
    openingHours: String,
  },
};
</script>
