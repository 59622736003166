<template>
    <section id="nova-mais">
      <div
        class="px-8 pt-4 bg-main-yellow-500 md:bg-[url('@/assets/img/pattern_nova_purple.png')] md:bg-auto md:bg-no-repeat md:bg-right flex justify-center items-center"
      >
        <div class="w-full md:w-2/3 text-white">
          <div class="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-2 items-center">
            <div>
              <h3 class="text-2xl md:text-4xl font-regular tracking-tighter">
                Indique um amigo e
              </h3>
              <h1 class="text-7xl md:text-9xl font-black md:font-extrabold text-main-purple-500 uppercase tracking-tighter">ganhe</h1>
              <h2 class="text-5xl md:text-5xl font-black md:font-extrabold text-main-pink-500 uppercase tracking-tighter mt-0">50% de desconto</h2>
              <h3 class="text-4xl md:text-4xl font-regular tracking-tighter">
                na mensalidade!
              </h3>
            </div>
            <div>
              <img
                class="mt-3 md:my-0"
                src="@/assets/img/indicacao.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>
  export default {
    name: "BoxIndicacao",
  };
  </script>
  