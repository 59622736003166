<template>
    <div class="about-us">
      <main
        class="relative min-h-screen flex flex-col justify-center overflow-hidden"
      >
        <div class="w-full mx-auto px-4 md:px-6 mt-20">
          <div class="w-full max-w-7xl mx-auto">
            <div class="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-3 items-start">
                <div>
                    <img src="@/assets/img/pose-1.png" />
                </div>
                <div class="col-span-2">
                    <div class="bg-main-yellow-500 rounded-3xl p-6 max-w-2xl text-xl text-white mb-8">Olá, pessoal. Tudo bem? <br />
                        Prazer, eu sou a Ivy! <br />
                        Cheguei há pouco tempo aqui na Nova e <strong class="font-extrabold">estou muito feliz em fazer parte deste time sensacional!</strong>
                    </div>
                    <div class="bg-main-pink-500 rounded-3xl p-6 max-w-lg text-xl text-white mb-8">E pra gente se conhecer melhor vou te contar
                        <strong class="font-extrabold">algumas curiosidades sobre mim!</strong>
                    </div>
                    <h1 class="text-4xl text-main-pink-500 font-black uppercase mt-10 mb-4">Quem sou eu?</h1>
                    <div class="bg-main-purple-500 rounded-3xl p-6 max-w-2xl text-xl mb-8 text-white">
                        <ol class="list-none list-inside">
                            <li class="mb-4"><strong class="text-main-yellow-500 font-bold">01 - </strong>Sou aquariana, adoro inovação e criatividade</li>
                            <li class="mb-4"><strong class="text-main-yellow-500 font-bold">02 - </strong>Apaixonada pelo mundo digital, afinal minha vida é a internet</li>
                            <li class="mb-4"><strong class="text-main-yellow-500 font-bold">03 - </strong>Adoro músicas e tenho várias playlists diferentonas</li>
                            <li class="mb-4"><strong class="text-main-yellow-500 font-bold">04 - </strong>Sempre invisto em makes, e uma boa dica: vejo vários tutoriais na internet</li>
                            <li class="mb-4"><strong class="text-main-yellow-500 font-bold">05 - </strong>Na culinária eu me viro e sempre conto com a ajuda do Google</li>
                            <li class="mb-4"><strong class="text-main-yellow-500 font-bold">06 - </strong>E como eu amo a internet, eu já acordo ligada nas redes</li>
                            <li class="mb-4"><strong class="text-main-yellow-500 font-bold">07 - </strong>Sou muito grudada a minha família, inclusive aos meus xodós de 4 patas</li>
                            <li class="mb-4"><strong class="text-main-yellow-500 font-bold">08 - </strong>Sou bem ativa, gosto de correr e tenho amor pelo boxe</li>                    
                        </ol>
                    </div>                    
                </div>
            </div>
            <div class="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-3 items-center">
                <div>
                    <img src="@/assets/img/banner_ivy.png" />
                </div>
                <div class="col-span-2">
                    <div class="p-4 max-w-2xl">
                        <h1 class="text-main-pink-500 text-4xl font-regular tracking-tighter italic">Me <strong class="font-bold">CONECTO COM PESSOAS O DIA TODO</strong>, trabalho para deixar a vida do usuário sempre mais leve e <strong class="font-bold">ME PREOCUPO EM ATENDER COM AGILIDADE</strong> quem está do outro lado.</h1>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-3 items-center">
                <div class="col-span-2">
                    <div class="bg-main-pink-500 rounded-3xl p-6 text-xl text-white mb-8">
                        <p>Você cliente, é o meu maior tesouro <strong class="font-extrabold">e me comprometo em te deixar sempre confortável com o maior número de informações possíveis,</strong> combinado?</p>
                        <p>Afinal, estou aqui para estreitar os laços e promover uma experiência única com você!!</p>

                        <p>Conte comigo sempre</p>
                    </div>
                </div>
                <div>
                    <img src="@/assets/img/ivy_pose_2.png" />
                </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </template>
  <script>
  export default {
    name: "Ivy",
  };
  </script>
  