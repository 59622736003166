<template>
  <div class="aspect-w-16 aspect-h-9 my-4">
    <iframe
      class="rounded-xl"
      :src="`https://www.youtube.com/embed/${embedLink}`"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    embedLink: String,
  },
};
</script>
