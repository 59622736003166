<template>
    <div class="z-[999999999] fixed bg-black/50 w-full h-screen flex top-0 left-0 items-center justify-center overflow-y-hidden" @click="hideModal()" v-if="this.showModal">
        <div
            v-if="this.responseMessage == 'error'"
            class="relative p-8 rounded-3xl bg-main-purple-500 w-10/12 sm:w-full sm:max-w-4xl text-white md:bg-[url('@/assets/img/pattern_nova_purple_lg.png')] md:bg-contain md:bg-no-repeat md:bg-right grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-5 items-center"
        >
            <div class="col-span-3">
                <h1 class="text-6xl sm:text-9xl text-main-yellow-500 font-black mb-6 uppercase tracking-tighter">Ops!</h1>
                <p class="text-lg sm:text-xl font-medium">Não conseguimos identificar a cobertura da Nova no seu endereço em nosso sistema. Vamos pedir a central de vendas para entrar em contato e confirmar as informações.</p>
            </div>
            <div class="col-span-2">
                <img
                    class="mt-3 md:my-0 -rotate-12"
                    src="@/assets/img/cobertura_erro.png"
                />
            </div>
            <div class="absolute top-0 right-0">
                <p class="font-extrabold text-main-pink-500 bg-white px-2 py-1 rounded-md text-lg cursor-pointer -mr-2 -mt-2" @click="hideModal()">X</p>
            </div>
        </div>

        <div
            v-else-if="this.responseMessage == 'success'"
            class="p-8 rounded-3xl bg-main-pink-500 w-10/12 sm:w-full sm:max-w-4xl text-white md:bg-[url('@/assets/img/pattern_nova_pink_lg_alt.png')] md:bg-contain md:bg-no-repeat md:bg-right grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-5 items-center"
        >
            <div class="col-span-3">
                <h1 class="text-6xl sm:text-8xl text-main-yellow-500 font-black mb-6 uppercase tracking-tighter">Show!</h1>
                <p class="text-lg sm:text-xl font-medium">A <strong class="text-main-yellow-500 uppercase">ultravelocidade</strong> da Nova já está presente na sua região! Nossos consultores já vão entrar em contato e apresentar nossas <strong class="text-main-yellow-500 uppercase">super ofertas</strong>.</p>
            </div>
            <div class="col-span-2">
                <img
                    class="mt-3 md:my-0 -rotate-12"
                    src="@/assets/img/cobertura_ok.png"
                />
            </div>
            <div class="absolute top-0 right-0">
                <p class="font-extrabold text-main-pink-500 bg-white px-2 py-1 rounded-md text-lg cursor-pointer -mr-2 -mt-2" @click="hideModal()">X</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        // responseMessage: String,
        activateModal: Boolean
    },
    data: () => ({
        showModal: false,
        messageType: 'error'
    }),
    methods: {
        getModal(refReturn) {
            document.body.style.overflowY = 'hidden';
            this.showModal = true
            this.responseMessage = refReturn
        },
        hideModal() {
            this.showModal = false
            document.body.style.overflowY = 'auto';
        },
    },
    created() {
        // document.body.style.overflowY = 'hidden';
    }
}
</script>