<template>
    <div class="z-[999999999] fixed bg-black/50 w-full h-screen flex top-0 left-0 items-center justify-center overflow-y-hidden" v-if="this.showModal">
        <div
            class="p-0 bg-main-purple-500 w-1/3 lg:w-10/12 xl:max-w-2xl items-center"
        >
        <div class="relative px-0 right-0 text-right mb-8">
            <strong class="text-2xl align-right cursor-pointer text-white right-0 mr-3" @click="hideModal()">x</strong>
        </div>

            <div class="col-span-5">
                <img src="@/assets/img/popup_saida.jpg" />
            </div>
            <div class="col-span-5">
                <form @submit.prevent="this.sendContact()">
                    <div class="p-4 grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-3">
                        <div>
                            <input
                                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                                type="text"
                                placeholder="Digite seu nome"
                                name="clientName"
                                id="clientName"
                                v-model="clientName"
                                
                            />
                        </div>
                        <div>
                            <input
                            v-maska
                            data-maska="['(##)####-####', '(##)#####-####']"
                            class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                            type="text"
                            placeholder="Digite seu telefone"
                            name="clientPhone"
                            id="clientPhone"
                            v-model="clientPhone"
                            required
                            />
                        </div>
                        <div>
                            <button
                                class="w-full font-regular bg-main-yellow-500 text-main-purple-500 text-xl uppercase tracking-tight px-4 py-2 rounded-xl mx-auto"
                            >
                                <strong class="font-black">Enviar</strong>
                            </button>
                            
                        </div>

                    </div>
                </form>
                <div class="text-white font-semibold text-lg mb-2 text-center">
                    <span v-if="this.status == 'success'">Agradecemos a mensagem!</span>
                    <span v-else-if="this.status == 'error'">Ops, algo deu errado!</span>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import ContactForm from "@/mixins/ContactForm";

export default {
    props: {
        // responseMessage: String,
        activateModal: Boolean
    },
    mixins: [ContactForm],
    data: () => ({
        showModal: false,
        messageType: 'error',
        messageId: 7,
        clientDoc: '000.000.000-00',
        clientMessage: 'Prospecção na saída do site.',
    }),
    watch: {
        status: function() {
            var intervalId;

            let testeContagem = 5
            if (this.status == 'success') {
                intervalId = setInterval(function() {
                    testeContagem--
                    console.log(testeContagem)
                    if (testeContagem == 0) {
                        clearInterval(intervalId);
                    }
                }, 1000);
                this.hideModal()
            }
        },
    },
    methods: {
        getModal() {
            document.body.style.overflowY = 'hidden';
            this.showModal = true
        },
        hideModal() {

            // $cookies.set("testPromo", c);
            this.$cookies.set("popupPromo", true, 86400);
            this.$router.go(0);
            this.showModal = false
            document.body.style.overflowY = 'auto';
        },
    },
    created() {
        // document.body.style.overflowY = 'hidden';
    }
}
</script>