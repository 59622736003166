<template>
  <section id="nova-app">
    <div
      class="p-8 bg-main-pink-500 flex justify-center content-center items-center md:bg-[url('@/assets/img/pattern_nova_gray.png')] md:bg-auto md:bg-no-repeat md:bg-left"
    >
      <div class="w-11/12 md:w-3/4 text-white">
        <div
          class="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-2 items-center"
        >
          <div class="order-2 md:order-1">
            <!-- component -->
            <div class="flex justify-center">
              <div>
                <img
                  class="my-3 md:my-0 w-11/12 md:w-3/4"
                  src="@/assets/img/app_nova.png"
                />
              </div>
            </div>
          </div>
          <div class="order-1 md:order-2">
            <h2 class="text-4xl font-bold">
              <span class="font-black text-main-yellow-500">Baixe o aplicativo da</span>
            </h2>
            <h1
              class="text-6xl font-black text-main-purple-500 uppercase tracking-tighter"
            >
              Nova Telecom!
            </h1>
            <h3
              class="text-xl md:text-lg font-medium mt-3 leading-5 md:leading-5"
            >
              Acesso rápido ao serviço de atendimento do consumidor da Nova. É
              uma ferramenta em que você pode realizar emissões de 2ª via de
              boletos, análise de consumo diário ou mensal de suas conexões,
              efetuar pagamento online de mensalidades, visualizar protocolos e
              cartilha de segurança da WEB conforme normativas da Anatel, dentre
              outras funcionalidades.
            </h3>

            <div class="sm:grid sm:grid-cols-2 items-center my-4">
                <google-play-button />
                <app-store-button />
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import GooglePlayButton from "@/components/layout/goods/GooglePlayButton.vue";
import AppStoreButton from "@/components/layout/goods/AppStoreButton.vue";

export default {
  name: "BoxNovaApp",
  components: {
    GooglePlayButton,
    AppStoreButton,
  },

};
</script>
