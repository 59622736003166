<template> 
  <div
    class="px-4 flex items-center w-full min-h-screen bg-main-purple-500 sm:bg-[url('@/assets/img/pattern_nova_pink_lg.png')] sm:bg-left sm:bg-fixed sm:bg-no-repeat sm:bg-contain"
  >
    <div class="grid sm:grid-cols-5 w-full">
      <div class="col-span-2"></div>
      <div class="col-span-2 p-8">
        <div>
          <div>
            <img
              class="w-10/12 sm:w-1/2"
              src="@/assets/img/logo_nova_bco.png"
            />
            <h1 class="font-extrabold text-3xl text-main-yellow-500 my-6">
              Sua conexão começa aqui!
            </h1>
          </div>
          <div class="mt-10">
            <ul class="text-white font-bold text-xl space-y-2">
              <li
                v-for="(l, key) in this.response.data"
                :key="key"
                class="hover:border-l-8 hover:ps-2 hover:border-l-main-yellow-500 cursor-pointer"
                @click="this.saveCity(l.id)"
              >
                {{ l.name }} - MG
              </li>
            </ul>
            <new-city ref="newCityModal" :response-message="this.response" />

            <button
              class="p-4 border-2 rounded-xl border-main-yellow-500 font-bold text-md text-main-yellow-500 mt-8"
              @click="helloBola()"
            >
              Não encontrei minha cidade
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CitySelection from "@/mixins/CitySelection";
import LoadUrl from "@/mixins/LoadUrl";
import NewCity from "@/components/layout/home/NewCity";

export default {
  name: "Preload`",
  components: {
    NewCity,
},
  mixins: [CitySelection, LoadUrl],
  methods: {
    helloBola() {
      this.$refs.newCityModal.getModal();
    },

  },
  created() {
    this.loadList();
  },
};
</script>
