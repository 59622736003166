<template>
  <div class="mb-8 sm:mb-0" v-if="tipoPlano == 2">
   <!--box plano-->
   <div class="border-enterprise-green-500 border-2 rounded-3xl sm:h-full">

      <div class="plano_empresa">

        <div v-if="velocidadePromocional == 0">
          <h1 class="text-center text-7xl font-black tracking-tight text-enterprise-green-500">
            {{ velocidadePlano }}
          </h1>
          <div class="text-center mt-2 mb-4">
            <span class="bg-enterprise-green-800 text-white px-3 py-2 rounded-xl font-black text-2xl uppercase">mega</span>
          </div>
        </div>

        <div v-if="velocidadePromocional > 0">
          <p class="text-center text-sm text-gray-500 my-4">Compre</p>
          <h1 class="text-center text-4xl font-black tracking-tight text-main-pink-500">
            <span>{{ velocidadePlano }}</span>
            <span class="bg-enterprise-green-800 text-white px-2 py-1 rounded-xl font-black text-sm uppercase mega-promocional">mega</span>
          </h1>
          <p class="text-center text-sm text-gray-500 mt-2">Leve</p>
          <h1 class="text-center text-7xl font-black tracking-tight text-enterprise-green-500" v-if="velocidadePromocional == 0">
            {{ velocidadePlano }}
          </h1>
          <h1 class="text-center text-7xl font-black tracking-tight text-main-pink-500" v-else >
            {{ velocidadePromocional }}
          </h1>

          <div class="text-center mt-2 mb-4">
            <span class="bg-enterprise-green-800 text-white px-3 py-2 rounded-xl font-black text-2xl uppercase">mega</span>
          </div>
        </div>

      </div>

      <div class="rodape_plano">
        <div class="mb-3 p-3 text-main-purple-500 text-center font-black tracking-tighter" v-if="showSpeed">
          <span class="text-2xl">R$</span>
          <span class="text-5xl">{{ this.planMajor }}</span>
          <span class="text-2xl">,{{ this.planCents }}0</span>
        </div>
        <div class="content-center text-center my-3">
          <router-link
            class="mt-2 py-2 px-4 bg-enterprise-green-500 text-white text-lg font-extrabold uppercase rounded-xl shadow-md hover:bg-enterprise-green-800 focus:outline-none focus:ring-2 focus:bg-enterprise-green-800 focus:ring-opacity-75 bottom-4 relative"
            :to="{ name: 'Buy', params: { planId: idPlano } }" :id="'plan_' + this.idPlano" :name="'plan_' + this.idPlano"
          >
            Assine já!
          </router-link>
          <p class="text-sm font-semibold text-enterprise-green-800 tracking-tight leading-4">
            *Consulte condições
          </p>
        </div>        
      </div>

    </div>
    <!--fim box plano-->    
  </div>
  <div class="mb-8 sm:mb-0" v-else>
    <!--box plano especial-->
    
    <!--fim box plano-->

    <!--box plano-->
    <div class="border-main-purple-500 border-2 rounded-3xl sm:h-full" :class="{'bg-main-yellow-500':destacaPlano}">
      <div class=" text-white text-sm font-medium text-center plano-destaque leading-4">
        <img class="w-full" src="@/assets/img/plano-destaque_pink.png" v-if="idPlano == 20"/>
        <img class="w-full" src="@/assets/img/plano-destaque.png" v-else/>
        <span>{{ tituloPlano }}</span>
      </div>

      <div class="mt-3" :class="showSpeedHeight">
        <div class="w-full md:text-center md:h-5">
          <span v-if="precoPromocional > 0" class="italic tracking-tighter text-main-purple-200 font-extrabold text-center text-sm">Tempo Limitado</span>
        </div>

        <div v-if="idPlano > 25">
          <h1 class="text-center text-7xl font-black tracking-tight text-main-pink-500">
            {{ velocidadePlano }}
          </h1>
          <div class="text-center mt-2 mb-4">
            <span class="bg-yellow-300 text-main-purple-500 px-3 py-2 rounded-xl font-black text-2xl uppercase">mega</span>
          </div>
        </div>

        <div v-if="idPlano <= 25"> 
          <h1 class="text-center text-6xl md:text-5xl 2xl:text-6xl font-black tracking-tight text-main-pink-500 mb-6" :class="{'text-white':destacaPlano}">
            <span>{{ velocidadePlano }}</span>
            <span class=" text-main-purple-500 font-black text-lg uppercase" :class="{'text-white':destacaPlano}"> mega</span>
          </h1>
        </div>
        <div class="px-4 text-left text-xs subtitle h-14">
          <div>
            <p>{{ subtituloPlano }}</p>
          </div>
        </div>

      </div>

      <div class="rodape_plano flex justify-center items-end pb-6">
        <div class="mb-2">
          <div class="info-price text-center">
            <!-- <img class="w-3/5 mb-2 mx-auto" src="@/assets/img/nova_mais.png" /> -->
            <!-- <p class="mt-5 font-bold text-md text-main-purple-500" v-if="velocidadePlano == 800"><img src="@/assets/img/roteador.png" class="-mt-3 w-6 mr-4 inline-block" />Rede Mesh</p> -->
            <box-sva :svaPlano="svaPlano" :idPlano="idPlano" />
          </div>        
        </div>
      </div>

      <div class="rodape_plano flex justify-center items-end md:h-40">
        <div class="mb-2">
          
          <div v-if="precoPromocional > 0">
            <div class="text-md line-through text-center text-main-pink-500 font-extrabold">
              De <span class="line-through">R${{ this.planMajor }}</span>
              <span class="line-through" v-if="this.planCents > 0">,{{ this.planCents }}0</span>
              <span class="line-through" v-else>,00</span>
            </div>
  
            <div class="mt-0 p-1 text-main-purple-500 text-center font-black tracking-tighter">
              <span class="text-2xl">R$ </span>
              <span class="text-5xl">{{ this.promoMajor }}</span>
              <span class="text-2xl">,{{ this.promoCents }}0*</span>
            </div>
  
          </div>
          <div v-else class="mt-2 p-1 text-main-purple-500 text-center font-black tracking-tighter">
            <span class="text-2xl">R$</span>
            <span class="text-5xl">{{ this.planMajor }}</span>
            <span class="text-2xl" v-if="this.planCents > 0">,{{ this.planCents }}0</span>
            <span class="text-2xl" v-else>,00*</span>

          </div>
          <div class="content-center text-center mt-5">
            <router-link
              class="mt-1 py-2 px-4 bg-green-500 text-white text-lg font-extrabold uppercase rounded-xl shadow-md hover:bg-main-yellow-700 focus:outline-none focus:ring-2 focus:main-yellow-800 focus:ring-opacity-75 bottom-4 relative"
              :to="{ name: 'Buy', params: { planId: idPlano } }" :id="'plan_' + this.idPlano" :name="'plan_' + this.idPlano"
            >
              Assine já!
            </router-link>
          </div>
          <p class="text-xs font-medium text-main-pink-500 tracking-tight leading-4 px-2 my-2 text-center">
            {{ extrasPlano }}
          </p>

        </div>
      </div>

    </div>
    <!--fim box plano-->
  </div>
</template>
<style scoped>
.info-price {
  min-height:65px;
  display: flex;
    flex-direction: column;
    justify-content:end;
    align-items: center;
}
.btn-neon {
  border:3px solid #fc3a69;
  position: relative;
  cursor: pointer;
  box-shadow: inset 0 0 0.5em 0 #fc3a69, 0 0 0.5em 0 #fc3a69;
}
.txt-neon {
  cursor: pointer;
  text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 20px #fc3a69, 0 0 10px #fc3a69;
}
.btn-neon:hover {
  color: #f5f5f5;
  border:3px solid #f5f5f5;
  transition:0.4s;
}
.txt-neon:hover {
  color:white;
  transition:0.4s;
}

.velocidade_plano {
  @media (min-width: 768px) {
    /* min-height:140px; */
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
  }
}
.plano_empresa {
  @media (min-width: 768px) {
    min-height:135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.mega-promocional {
  margin-left: -0.5rem;
}
.plano-destaque {
  position:relative;
  margin-top: -1rem;
  padding-left:1rem;
  padding-right:1rem;

}
.plano-destaque > span {
  position:absolute;
  top:50%;
  left:2.5rem;
  right:2.5rem;
  transform:translateY(-50%);

}
</style>
<script>
import BoxSva from '@/components/layout/plans/BoxSVA.vue';

export default {
  name: "BoxPlan",
  components: {BoxSva},
  data() {
    return {
      planMajor: this.getPlanValue()[0],
      planCents: this.getPlanValue()[1],
      promoMajor: this.getPromotionalValue()[0],
      promoCents: this.getPromotionalValue()[1],
      destacaPlano: this.idPlano == 18 || this.idPlano == 22,
      showSpeedHeight: this.boxSpeedHeight()
    };
  },
  props: {
    tituloPlano: String,
    subtituloPlano: String,
    extrasPlano: String,
    velocidadePlano: Number,
    velocidadePromocional: Number,
    precoPlano: Number,
    precoPromocional: Number,
    svaPlano: Array,
    idPlano: Number,
    tipoPlano: Number,
    cidadePlano: Number,
  },
  methods: {
    getPlanValue() {
      let preco = this.precoPlano;
      let digits = preco?.toString().split(".");
      return digits;
    },
    boxSpeedHeight() {
      if(this.cidadePlano > 5) {
        return "h-40"
      } else {
        return "h-36"
      }
    },
    getPromotionalValue() {
      let promoPlano = this.precoPromocional;
      if (promoPlano == 0) { promoPlano == 0.00 }
      let digitsPromo = promoPlano?.toString().split(".");
      return digitsPromo;
    },
  },
  created() {
    this.getPlanValue();
  },
};
</script>
