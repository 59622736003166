<template>
  <section id="viabilidade-index" class="mt-6">
    <div>
      <modal-box ref="getModalBox" :response-message="this.returnStatus" />
    </div>

    <div
      class="p-6 rounded-md rounded-br-3xl w-11/12 md:w-1/2 mx-auto"
      :class="extraClass"
    >
      <div class="w-auto md:3/5">
        <h2 class="text-main-yellow-500 font-extrabold text-3xl leading-7 sm:text-xl 2xl:text-2xl mb-6 sm:text-center">
          Consulte nossa disponibilidade em seu endereço!
        </h2>
      </div>

      <form @submit.prevent="this.returnAddress()">
        <div
          class="grid grid-rows-4 md:grid-rows-1 grid-flow-col gap-2 md:gap-4 items-end"
        >
          <div>
            <label for="cep" class="text-xl text-white font-semibold"
              >CEP:</label
            >
            <input
              type="text"
              name="cep"
              id="cep"
              class="form-input px-3 py-2 mt-2 mb-4 rounded-xl w-full"
              v-model="cep"
              required
              v-maska
              data-maska="##.###-###"
            />
          </div>
          <div>
            <label for="numero" class="text-xl text-white font-semibold"
              >Nº do imóvel:</label
            >
            <input
              type="number"
              name="numero"
              id="numero"
              class="form-input px-3 py-2 mt-2 mb-4 rounded-xl w-full"
              v-model="addressNumber"
              required
            />
          </div>
          <div>
            <label for="phoneNumber" class="text-xl text-white font-semibold"
              >Celular:</label
            >
            <input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              class="form-input px-3 py-2 mt-2 mb-4 rounded-xl w-full"
              v-model="phoneNumber"
              required
              v-maska
              data-maska="(##)#####-####"
            />
          </div>
          <div>
            <button
              class="py-2 px-4 bg-main-yellow-500 text-white text-lg font-extrabold uppercase rounded-xl shadow-md hover:bg-main-yellow-700 focus:outline-none focus:ring-2 focus:main-yellow-800 focus:ring-opacity-75 bottom-4 relative"
            >
              Consultar
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import ModalBox from "@/components/layout/goods/ModalBox.vue";

export default {
  name: "CheckAvailability",
  props: {
    extraClass: String,
  },
  components: {
    ModalBox,
  },
  data: () => ({
    cep: null,
    addressNumber: null,
    phoneNumber: null,
    divActive: null,
    errors: [],
    response: [],
    returnStatus: null,
    responseTitle: null,
    fullPage: false,
  }),
  methods: {
    helloBola(teste) {
      this.$refs.getModalBox.getModal(teste);
    },
    formatString(string) {
      return string.toString().replace(/[^a-z0-9]/gi, "");
    },

    returnAddress() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        backgroundColor: "#FFC000",
        color: "#581E9B",
        opacity: 0.9,
      });
      let clientCep = this.formatString(this.cep);
      let addressNumber = this.formatString(this.addressNumber);
      let phoneNumber = this.formatString(this.phoneNumber);
      
      axios
        .get(
          this.apiUrl +
            "/TechnicalViability/" +
            clientCep +
            "/" +
            addressNumber +
            "/" +
            phoneNumber
        )
        .then((response) => {
          this.response = response.data.message;
          this.responseTitle = "Retorno da consulta:";
          if (response.data.message == "Porta disponivel.") {
            this.returnStatus = "success";
          } else {
            this.returnStatus = "error";
          }
        })
        .catch((errors) => {
          this.response = errors.response.data;
          this.responseTitle = "Ops, ocorreu um erro.";
          this.returnStatus = "error";
        })
        .finally(() => {
          this.helloBola(this.returnStatus);
          loader.hide();
        });
    },
  },
};
</script>
