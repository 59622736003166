<template>
    <vue-recaptcha v-show="showRecaptcha" sitekey="6LfFTcEoAAAAAAVazfBGUqJFtVG4WwvZq2aC-Uc9"
           size="normal" 
           theme="light"
           :loading-timeout="loadingTimeout"
           @verify="recaptchaVerified"
           @expire="recaptchaExpired"
           @fail="recaptchaFailed"
           @error="recaptchaError"
           ref="vueRecaptcha">
    </vue-recaptcha>
  </template>
  
  <script>
  import vueRecaptcha from 'vue3-recaptcha2';
  
  export default {
    name: 'captcha-box',
    components: {
      vueRecaptcha
    },
    data() {
      return {
          showRecaptcha: true,
          loadingTimeout: 30000 // 30 seconds
      }
    },
    methods: {
      recaptchaVerified(response) {
        console.log(response)
      },
      recaptchaExpired() {
        this.$refs.vueRecaptcha.reset();
        console.log('expirou')
      },
      recaptchaFailed() {
        console.log('falhou')
      },
      recaptchaError(reason) {
        console.log(reason)
      }
    }
  };
  </script>