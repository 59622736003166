import { useCitySelectionStore } from "@/stores/CitySelectionStores";
import axios from "axios";

export default {
  data: () => ({
    errors: [],
    response: [],
    cityValue: useCitySelectionStore(),
    urlBase: "city",
    resetCity: false,
    renderComponent: true,
    componentKey: 0,
    typeId: 1,
  }),
  methods: {
    plusRender() {
      this.componentKey += 1;
    },
    async forceRender() {
      // Remove MyComponent from the DOM
      this.renderComponent = false;
   
      // Then, wait for the change to get flushed to the DOM
         await this.$nextTick();
   
         // Add MyComponent back in
         this.renderComponent = true;
        //  console.log('forceRender teste')
       },
    changeTypeScreen(changeType = false) {
      console.log("teste");
      if(changeType == true) {
        console.log("change ok");
      }
    },
    newCityScreen(changeCity = false) {

      let routeName = this.$route.name
      console.log(routeName)

      if (routeName === "homeCampaign") {

        // this.cityValue.saveType(1);
        if(this.$cookies.get('cookieTeste') === null) {
          console.log('teste nulo')
          $cookies.set("cookieIndex", true);
          $cookies.set("cookieTeste", true);
          $cookies.set("selectType", 1);
          $cookies.set("selectCity", 1);
          // this.$router.go({name: 'home', params: {planType: ''}});
        }
      }

      let cityCookie = $cookies.get("selectCity");
      let cityPinia = this.cityValue.showList.dataCity;

      if (changeCity == true) {
        $cookies.remove("selectCity");
        this.cityValue.saveCity(null);
        // console.log("change city == true");
        return true;
      }

      if (cityCookie != null && cityCookie != 0) {
        if (cityPinia != null && cityPinia != 0) {
          return false;
        } else {
          this.cityValue.saveCity(cityCookie);
          return false;
        }
      } else {
        if (cityPinia != null && cityPinia != 0) {
          $cookies.set("selectCity", this.cityValue.showList.dataCity);
          return false;
        } else {
          return true;
        }
      }
    },
    saveCity(c = 1) {
      if ($cookies.get("selectCity")) {
        $cookies.remove("selectCity");
      }
      $cookies.set("selectCity", c);
      this.cityValue.saveCity(c);
    },
    
    chooseCityScreen() {
      $cookies.remove("selectCity");
      this.cityValue.saveCity(0);
      this.resetCity = true;
      this.$router.go(0);
    },

    saveType(c=1) {
      if($cookies.get("selectType")) {
        $cookies.remove("selectType");
      }
      $cookies.set("cookieIndex", true);

      $cookies.set("selectType", c);
      this.cityValue.saveType(c);
      if(this.$route.name == 'home') {
        // this.$router.replace({path: '/'});
        this.$router.go(0);
      } else {
        this.$router.replace({path: '/'});
        // this.$router.go(0);
      }
    },

    // saveType(c = 1) {
    //   if ($cookies.get("selectType")) {
    //     $cookies.remove("selectType");
    //   }
    //   $cookies.set("selectType", c);
    //   this.cityValue.saveType(c);
    //   if(this.$route.name == 'home') {
    //     this.$router.go(0)
    //   } else {
    //     this.$router.replace({ path: '/' })
    //     this.forceRender()
    //   }
    // },

    returnLog() {
      // console.log('inicia tipo Pinia', this.cityValue.showList.dataType);
      // console.log('inicia cidade Pinia', this.cityValue.showList.dataCity);
      return true;
    },
    userType() {
      let typeReturn;
      switch (this.cityValue.showList.type) {
        case "pessoaFisica":
          typeReturn = 1;
          break;
        case "pessoaJuridica":
          typeReturn = 2;
          break;
        default:
          typeReturn = 1;
          break;
      }
      return typeReturn;
    },
  },
  watch: {
    renderComponent() {
      if(this.renderComponent == "true") {
        this.plusRender();
        // console.log ("renderComponent true")
      } else {
        this.plusRender();
        // this.$router.go(0)
        // console.log("componentKey", this.componentKey)
      }
    }
  },
  created() {
    if (this.cityValue.showList.dataCity == 0) {
      if ($cookies.get("selectCity") !== null) {
        this.cityValue.saveCity($cookies.get("selectCity"));
        this.cityId = $cookies.get("selectCity");
      }
    } else {
      this.cityId = this.cityValue.showList.dataCity;
    }

    if (this.cityValue.showList.dataType == 0) {
      if ($cookies.get("selectType") !== null) {
        this.cityValue.saveType($cookies.get("selectType"));
        this.typeId = $cookies.get("selectType");
      }
    } else {
      this.typeId = this.cityValue.showList.dataType;
    }
    // console.log('cidade criada',this.cityId)
    // console.log('tipo criado',this.typeId)
  },
};
