<template>
  <!-- Item #1 -->
  <div class="relative pl-8 sm:pl-32 py-6 group">
    <!-- Purple label -->
    <!-- Vertical line (::before) ~ Date ~ Title ~ Circle marker (::after) -->
    <div
      class="flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-main-pink-500 sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-main-pink-600 after:border-4 after:box-content after:border-slate-50 after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5"
    >
      <time
        class="sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-extrabold uppercase w-20 h-6 mb-3 sm:mb-0 text-main-purple-500 bg-main-yellow-500 rounded-full"
        >{{ dateLabel }}</time
      >
      <div class="text-xl font-bold text-main-pink-500">
        {{ titleTimeline }}
      </div>
    </div>
    <!-- Content -->
    <div class="text-gray-800">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dateLabel: String,
    titleTimeline: String,
  },
};
</script>
