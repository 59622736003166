<template>
    <section id="ivy">
      <div
        class="px-8 pt-8 sm:pt-0 pb-0 bg-gray-100 md:bg-[url('@/assets/img/pattern_nova_gray.png')] md:bg-auto md:bg-no-repeat md:bg-right flex justify-center items-center"
      >
        <div class="w-full md:w-2/3 text-main-pink-500 mt-4">
          <div class="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-2 items-center">
            <div>
              <h2 class="text-4xl md:text-2xl 2xl:text-3xl font-bold leading-8">
                Faça como a <span class="font-black">Ivy e garanta a</span>
              </h2>
              <h1 class="text-5xl sm:text-4xl 2xl:text-6xl font-black text-main-purple-500 uppercase tracking-tighter">
                ultra&shy;velocidade
              </h1>
              <h1 class="text-6xl sm:text-4xl 2xl:text-7xl font-black text-main-pink-500 uppercase tracking-tighter">
                agora mesmo!
              </h1>

            <button-link
                link="conheca-ivy"
                button-color="bg-main-purple-500"
                font-color="text-main-yellow-500"
                font-size="text-2xl"
            >
                Clique <strong class="font-black">Aqui!</strong>
            </button-link>

            </div>
            <div>
              <img
                class="my-0"
                src="@/assets/img/ivy_pose_1.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>

    import ButtonLink from "@/components/layout/goods/ButtonLink.vue";
  
  export default {
    name: "BoxIvy",

    components: {
        ButtonLink,
    },

  };
  </script>
  