<template>
  <div class="about-us">
    <main
      class="relative min-h-screen flex flex-col justify-center overflow-hidden"
    >
      <div class="w-full mx-auto px-4 md:px-6 py-8 my-8">
        <div class="w-full max-w-4xl mx-auto mb-8">
          <h1
            class="font-black text-main-purple-500 text-6xl text-center tracking-tighter my-4"
          >
            Solicitar assinatura
          </h1>
          <h3
            class="font-regular text-main-pink-500 text-2xl text-center leading-6"
          >
            Insira seus dados
          </h3>
          <div
            class="p-6 border-2 rounded-xl my-4 bg-main-pink-500 text-white border-l-8 border-l-main-yellow-500"
          >
            <h3
              class="font-extrabold text-main-yellow-500 text-xl mb-2 text-center"
            >
              Plano selecionado: {{ this.response.description }}
            </h3>
          </div>
        </div>

        <return-box
          :return-status="`${this.status}`"
          :return-title="`${this.responseTitle}`"
          >{{ this.responseBody }}</return-box
        >

        <form @submit.prevent="this.sendContact()">
          <div class="w-full max-w-4xl mx-auto grid sm:grid-cols-2 gap-1">
            <div class="col-span-2 p-2">
              <input
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu nome"
                name="clientName"
                id="clientName"
                v-model="clientName"
                required
              />
            </div>
            <div class="p-2">
              <input
                v-maska
                data-maska="['###.###.###-##', '##.###.###/####-##']"
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu CPF ou CNPJ"
                name="clientDoc"
                id="clientDoc"
                v-model="clientDoc"
                required
              />
            </div>
            <div class="p-2">
              <input
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="email"
                placeholder="Digite seu e-mail"
                name="clientEmail"
                id="clientEmail"
                v-model="clientEmail"
                required
              />
            </div>
            <div class="p-2">
              <input
                v-maska
                data-maska="['(##)####-####', '(##)#####-####']"
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu telefone"
                name="clientPhone"
                id="clientPhone"
                v-model="clientPhone"
                required
              />
            </div>
            <div class="p-2">
              <input
                v-maska
                data-maska="['(##)####-####', '(##)#####-####']"
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Caso possua, insira outro número de telefone"
                name="clientPhoneAlt"
                id="clientPhoneAlt"
                v-model="clientPhoneAlt"
              />
            </div>
            <div class="col-span-2 p-2">
              <input
                class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                type="text"
                placeholder="Digite seu endereço"
                name="clientAddress"
                id="clientAddress"
                v-model="clientAddress"
                required
              />
            </div>

            <div class="col-span-2 p-2">
              <button
                class="font-regular bg-main-purple-500 text-main-yellow-500 text-xl uppercase tracking-tight px-4 py-3 rounded-xl my-4 float-right"
              >
                Enviar <strong class="font-black">Mensagem</strong>
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import BuyForm from "@/mixins/BuyForm";
import LoadUrl from "@/mixins/LoadUrl";

let paramsTeste = 1;
//   let paramsTeste = route.params.planId
//   let piroca = useRouteParam(planId)

export default {
  name: "Contato",
  mixins: [BuyForm, LoadUrl],
  data: () => ({
    messageId: 5,
    titleSuccess: "Mensagem enviada com sucesso!",
    messageSuccess:
      "Agradecemos o contato. Em breve, nossa equipe entrará em contato com você.",
    titleError: "Ops, algo deu errado...",
    messageError:
      "Nosso sistema não conseguiu enviar a mensagem neste momento. Por favor, tente novamente.",
    clientPlan: null,
    urlBase: null,
  }),
  methods: {
    getParamsId() {
      this.clientPlan = this.$route.params.planId;
      this.urlBase = "plan/" + this.$route.params.planId;
    },
  },
  created() {
    this.getParamsId();
  },
};
</script>
