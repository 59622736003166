<template>

    <div class="mb-8 sm:mb-0">

<!--box plano-->
<div class="border-main-purple-600 bg-main-purple-500 border-2 rounded-md sm:h-full shadow-md">
<!-- <div class="border-main-pink-100 border-2 rounded-md sm:h-full shadow-md" :class="{'bg-main-purple-500':destacaPlano}"> -->
  <div class=" ml-4 mt-6 mb-4">
    <span class= "text-main-purple-600 bg-white text-sm font-extrabold p-2 rounded-lg">Nova Play</span>
  </div>
  <div class="mt-3">
    <!-- <span class="italic tracking-tighter text-main-purple-200 font-extrabold">Tempo Limitado</span> -->

    <!-- <div v-if="idPlano > 21">
      <h1 class="text-7xl font-black tracking-tight text-main-pink-500 ml-4">
        750 megas
      </h1>
    </div> -->

    <div> 
      <h1 class="text-3xl md:text-3xl 2xl:text-5xl font-black tracking-tight text-main-pink-500 mb-1 mx-4">
        <span>650 MEGA</span>
      </h1>
    </div>

    <div class="text-white ml-4 my-2 font-black tracking-tighter">
      <span class="text-3xl">R$</span>
      <span class="text-3xl">114</span>
      <span class="text-3xl">,00</span>
    </div>

    <!-- <div class="px-16">
      <hr class="my-4 border-2" />
    </div> -->
    <div class="w-full p-0 my-4">
      <img src="@/assets/img/planos/nova_play.jpg" />
    </div>
    <div class="px-3 mb-2 text-left text-md text-white">
      <div>
        <p>Escute sua Playlist favorita onde estiver e quando quiser</p>
      </div>
    </div>

  </div>

  <div>
    <div class="mb-4">
    <div class="px-2 my-3">
        <div>
            <h4 class="ml-2 text-main-pink-500 font-extrabold mb-2">Apps inclusos:</h4>
            <!-- <h4 class="text-center text-main-purple-500 font-extrabold mb-2">Apps inclusos:</h4> -->
        </div>
        <div>
            <ul class="list-image-[url(@/assets/img/check.png)] list-inside underline text-white font-medium">
                <li><a href="https://rnova.com.br/nova-sva" target="_blank">Nova Mais</a></li>
                <li><a href="https://rnova.com.br/nova-sva" target="_blank">Wi-fi Mesh</a></li>
                <li><a href="https://rnova.com.br/nova-sva" target="_blank">Deezer</a></li>
                <li><a href="https://rnova.com.br/nova-sva" target="_blank">Skeelo</a></li>
            </ul>
        </div>
    </div>       

      <div class="mt-7 ml-4">
        <router-link
          class="my-4 mr-2 py-3 px-5 bg-main-pink-500 text-white text-xl font-extrabold rounded-lg shadow-md hover:bg-main-yellow-700 focus:outline-none focus:ring-2 focus:main-yellow-800 focus:ring-opacity-75 bottom-4"
          :to="{ name: 'Buy', params: { planId: 18 } }" id="plan_18" name="plan_18"
        >
          Assine já!
        </router-link>
      </div>
      <p class="text-xs font-medium text-main-pink-500 tracking-tight leading-4 px-2 mt-8 mb-2 ml-2">
        Taxa de instalação e adesão grátis!*
      </p>

    </div>
  </div>

</div>
<!--fim box plano-->
</div>
</template>

<style scoped>
.info-price {
min-height:65px;
display: flex;
flex-direction: column;
justify-content:end;
align-items: center;
}
.btn-neon {
border:3px solid #fc3a69;
position: relative;
cursor: pointer;
box-shadow: inset 0 0 0.5em 0 #fc3a69, 0 0 0.5em 0 #fc3a69;
}
.txt-neon {
cursor: pointer;
text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 20px #fc3a69, 0 0 10px #fc3a69;
}
.btn-neon:hover {
color: #f5f5f5;
border:3px solid #f5f5f5;
transition:0.4s;
}
.txt-neon:hover {
color:white;
transition:0.4s;
}

.velocidade_plano {
@media (min-width: 768px) {
min-height:140px;
display: flex;
flex-direction: column;
justify-content:flex-start;
align-items: center;
}
}
.plano_empresa {
@media (min-width: 768px) {
min-height:135px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
}
}
.mega-promocional {
margin-left: -0.5rem;
}
.plano-destaque {
position:relative;
margin-top: -1rem;
padding-left:1rem;
padding-right:1rem;

}
.plano-destaque > span {
position:absolute;
top:50%;
left:2.5rem;
right:2.5rem;
transform:translateY(-50%);

}
</style>