import axios from "axios";
import ReturnBox from "@/components/layout/goods/ReturnBox.vue";

export default {
  components: {
    ReturnBox,
  },
  data: () => ({
    errors: [],
    response: [],
    responseTitle: null,
    responseBody: null,
    fullPage: false,
    status: null,
  }),
  methods: {
    formatString(string) {
      return string.toString().replace(/[^a-z0-9]/gi, "");
    },

    sendContact() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        backgroundColor: "#FFC000",
        color: "#581E9B",
        opacity: 0.9,
      });
      let clientDoc = this.formatString(this.clientDoc);
      let clientPhone = this.formatString(this.clientPhone);

      axios
        .post(this.apiUrl + "/job", {
            employee_name: this.clientName,
            employee_doc: clientDoc,
            employee_phone: clientPhone,
            employee_message: this.clientMessage,
            employee_curriculum: this.selectFiles,
        },{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
          this.response = response.data;
          this.status = "success";
          this.responseTitle = this.titleSuccess;
          this.responseBody = this.messageSuccess;
        })
        .catch((errors) => {
          this.response = errors.response.data;
          this.status = "error";
          this.responseTitle = this.titleError;
          this.responseBody = this.messageError;
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
