<template>
  <div>
    <button
      :class="`font-regular ${buttonColor} ${fontColor} ${fontSize} uppercase tracking-tight px-8 py-6 rounded-3xl my-4`"
      @click="openInNewTab()"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    link: String,
    buttonColor: String,
    fontColor: String,
    fontSize: String,
  },
  methods: {
    openInNewTab() {
      window.open(this.link, "_blank", "noreferrer");
    },
  },
};
</script>
