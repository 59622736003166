<template>
    <div>
        Empresas
        {{ this.cityValue.showList.dataType }}
    </div>
</template>

<script>
import CitySelection from "@/mixins/CitySelection";
export default {
  mixins: [CitySelection],
};
</script>
