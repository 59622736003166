<template>
    <div class="z-[999999999] fixed bg-black/50 w-full h-screen flex top-0 left-0 items-center justify-center overflow-y-hidden" v-if="this.showModal">
        <div
            class="p-8 rounded-3xl bg-main-pink-500 w-10/12 sm:w-full sm:max-w-4xl text-white md:bg-[url('@/assets/img/pattern_nova_pink_lg_alt.png')] md:bg-contain md:bg-no-repeat md:bg-right sm:gap-6 sm:grid-cols-5 items-center static"
        >
        <div class="relative px-0 right-0 text-right -mt-4 mb-8">
            <strong class="text-2xl align-right cursor-pointer text-white right-0" @click="hideModal()">x</strong>
        </div>

            <div>
                <h1 class="text-xl sm:text-3xl text-main-yellow-500 font-black mb-6 uppercase tracking-tighter leading-6">Preencha o formulário abaixo e, assim que estivermos em sua cidade, iremos te avisar.</h1>
                <return-box
                    :return-status="`${this.status}`"
                    :return-title="`${this.responseTitle}`"
                    >{{ this.responseBody }}</return-box
                >

                <div>
                    
                    <form @submit.prevent="this.sendContact()">
                        <div class="w-full max-w-4xl mx-auto grid sm:grid-cols-2 gap-1">
                            <div class="col-span-2 p-2">
                                <input
                                    class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                                    type="text"
                                    placeholder="Digite seu nome"
                                    name="clientName"
                                    id="clientName"
                                    v-model="clientName"
                                    required

                                />
                            </div>
                            <div class="col-span-2 p-2">
                                <input
                                    v-maska
                                    data-maska="['(##)####-####', '(##)#####-####']"
                                    class="w-full form-input font-regular bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                                    type="text"
                                    placeholder="Digite seu telefone"
                                    name="clientPhone"
                                    id="clientPhone"
                                    v-model="clientPhone"
                                    required

                                />
                            </div>
                            <div class="col-span-2 md:col-span-1 p-2">
                                <select
                                    class="w-full form-select font-regular text-gray-500 bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                                    name="clientState"
                                    id="clientState"
                                    v-model="clientState"
                                    required
                                    @change="loadCity()"
                                >
                                    <option
                                        v-for="(l, stateKey) in this.response"
                                        :key="stateKey"
                                        v-bind:value="l.id"
                                        >{{l.nome}}
                                    </option>
                                    

                                </select>

                            </div>
                            <div class="col-span-2 md:col-span-1 p-2">
                                <select
                                    class="w-full form-select font-regular text-gray-500 bg-gray-100 border-main-pink-500 text-lg tracking-tight px-4 py-2 rounded-xl"
                                    name="clientCity"
                                    id="clientCity"
                                    v-model="clientCity"
                                    required
                                >
                                    <option
                                        v-for="(c, cityKey) in this.cityResponse"
                                        :key="cityKey"
                                        v-bind:value="c.nome + ' - ' + c.microrregiao.mesorregiao.UF.sigla"
                                        >{{c.nome}}
                                    </option>

                                </select>
                            </div>

                            <div class="col-span-2 sm:col-span-1 p-2 mx-auto">
                                <button
                                    class="font-regular bg-main-purple-500 text-main-yellow-500 text-xl uppercase tracking-tight px-4 py-2 sm:px-8 sm:py-6 rounded-3xl my-4 mx-auto" 
                                >
                                    Enviar <strong class="font-black">Mensagem</strong>
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios'
import NewCityForm from "@/mixins/NewCityForm";

export default {
    props: {
        // responseMessage: String,
        activateModal: Boolean
    },
    mixins: [NewCityForm],
    data: () => ({
        errors: [],
        response: [],
        cityErrors: [],
        cityResponse: [],
        clientState: 31,
        clientCity: null,
        clientName: null,
        clientPhone: null,
        showModal: false,
        messageType: 'error',
        clientMessage: null,

        messageId: 9,
        titleSuccess: "Mensagem enviada com sucesso!",
        messageSuccess:
        "Agradecemos o contato. Em breve, nossa equipe entrará em contato com você.",
        titleError: "Ops, algo deu errado...",
        messageError:
        "Nosso sistema não conseguiu enviar a mensagem neste momento. Por favor, tente novamente.",

    }),
    computed: {
        teste() {
            return this.clientMessage = this.clientState
        },
    },
    methods: {
        loadCity(e) {
            let selectState = this.clientState;
            axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/'+ selectState +'/municipios?orderBy=nome')
            .then((cityResponse) => {
                // handle success
                this.cityResponse = cityResponse.data
            })
            .catch((cityErrors) => {
                // handle error
                this.cityResponse = cityErrors
            })
            .finally(() => {
                // always executed
            });
        },
        loadState(e) {

            axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome')
            .then((response) => {
                // handle success
                this.response = response.data
            })
            .catch((errors) => {
                // handle error
                this.response = errors
            })
            .finally(() => {
                // always executed
            });
        },
        getModal(refReturn) {
            document.body.style.overflowY = 'hidden';
            this.showModal = true
            this.responseMessage = refReturn
        },
        hideModal() {
            this.showModal = false
            document.body.style.overflowY = 'auto';
        },
    },
    created() {
        // document.body.style.overflowY = 'hidden';
    },
    mounted() {
        this.loadState();
        this.loadCity();
    }
}
</script>