<template>
  <div>
    <div
      v-if="returnStatus == 'success'"
      class="border-dotted border-2 border-main-yellow-500 rounded-lg bg-main-purple-500 w-11/12 md:w-1/2 my-4 p-5 mx-auto"
      :class="extraClass"
    >
      <h3 class="font-extrabold text-2xl text-main-yellow-500">
        {{ returnTitle }}
      </h3>
      <hr class="my-4 border-main-pink-500 border-2" />
      <h5 class="font-medium text-lg text-white">
        <slot></slot>
      </h5>
    </div>

    <div
      v-else-if="returnStatus == 'error'"
      class="border-dotted border-2 border-main-purple-500 rounded-lg bg-main-yellow-500 w-11/12 md:w-1/2 my-4 p-5 mx-auto"
      :class="extraClass"
    >
      <h3 class="font-extrabold text-2xl text-main-pink-500">
        {{ returnTitle }}
      </h3>
      <hr class="my-4 border-main-pink-500 border-2" />
      <h5 class="font-medium text-lg text-main-purple-500">
        <slot></slot>
      </h5>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    returnTitle: String,
    returnStatus: String,
    extraClass: String
  },
};
</script>
