<template>
    <section id="solucoes-corporativas">
      <div
        class="p-8 bg-white flex justify-center items-center my-8"
      >
        <div class="w-full md:w-2/3 text-white">
            <div class="rounded-3xl bg-gray-600 p-6 text-center mb-20">
                <h1 class="font-extrabold text-3xl">CONHEÇA NOSSAS SOLUÇÕES CORPORATIVAS</h1>
            </div>
          <div class="grid grid-cols-1 sm:grid-cols-4 gap-8 sm:gap-6 items-center">
            <div>
                <div class="bg-main-pink-500 rounded-3xl p-3 text-center mb-8 sm:mb-0">
                    <img src="@/assets/img/solucao-1.png" class="mx-auto -mt-14" />
                    <span class="text-white font-extrabold text-xl uppercase">hospedagem</span>
                </div>
            </div>
            <div>
                <div class="bg-main-pink-500 rounded-3xl p-3 text-center mb-8 sm:mb-0">
                    <img src="@/assets/img/solucao-2.png" class="mx-auto -mt-14" />
                    <span class="text-white font-extrabold text-xl uppercase">IP fixo</span>
                </div>
            </div>
            <div>
                <div class="bg-main-pink-500 rounded-3xl p-3 text-center mb-8 sm:mb-0">
                    <img src="@/assets/img/solucao-3.png" class="mx-auto -mt-14" />
                    <span class="text-white font-extrabold text-xl uppercase">Link dedicado</span>
                </div>
            </div>
            <div>
                <div class="bg-main-pink-500 rounded-3xl p-3 text-center mb-8 sm:mb-0">
                    <img src="@/assets/img/solucao-4.png" class="mx-auto -mt-14" />
                    <span class="text-white font-extrabold text-xl uppercase">Wi-Fi</span>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>
  export default {
    name: "BoxIndicacao",
  };
  </script>
  