<template>
  <section id="nova-mais">
    <div
      class="p-8 bg-main-purple-500 md:bg-[url('@/assets/img/pattern_nova_purple.png')] md:bg-auto md:bg-no-repeat md:bg-right flex justify-center items-center"
    >
      <div class="w-11/12 md:w-2/3 text-white">
        <div class="grid grid-cols-1 gap-8 sm:gap-6 sm:grid-cols-2 items-center">
          <div>
            <h2 class="text-4xl md:text-3xl font-bold">
              Chegou o <span class="font-black">Clube de Vantagens</span>
            </h2>
            <h1
              class="text-8xl md:text-7xl font-extrabold text-main-yellow-500 uppercase tracking-tighter"
            >
              Nova Mais!
            </h1>
            <h3
              class="text-2xl md:text-xl font semibold mt-3 leading-6 md:leading-5"
            >
              <span class="font-black">+ de 26 mil parceiros</span> em todo o
              Brasil e <br /><span class="font-black"
                >+ de 600 mil produtos</span
              >
              com descontos!
            </h3>
          </div>
          <div>
            <img
              class="my-3 md:my-0"
              src="@/assets/img/nova_mais_partners.png"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "BoxNovaMais",
};
</script>
