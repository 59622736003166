<template>
  <div
    class="px-4 flex items-center w-full min-h-screen bg-main-purple-500 sm:bg-[url('@/assets/img/pattern_nova_pink_lg.png')] sm:bg-left sm:bg-fixed sm:bg-no-repeat sm:bg-contain"
  >
  MUDAR TIPO
  </div>
</template>

<script>
import CitySelection from "@/mixins/CitySelection";
import LoadUrl from "@/mixins/LoadUrl";

export default {
  name: "Preload`",
  mixins: [CitySelection, LoadUrl],
  methods: {
    helloBola() {
      this.$refs.newCityModal.getModal();
    },
    caralhoVoador() {
    //   if($cookies.get("cookieIndex")) {
    //     console.log($cookies.get("cookieIndex"));
    //     $cookies.remove("cookieIndex");
    //     this.$router.push({path: '/'});
    //     this.$router.replace({path: '/'});

    //     // $cookies.remove("selectType");
    //   }
    console.log('teste')
    },

  },
  created() {
    this.caralhoVoador()
  },
};
</script>
