<template>

  <div class="mb-8 sm:mb-0" v-if="tipoPlano == 2">
   <!--box plano-->
   <div class="border-enterprise-green-500 border-2 rounded-3xl sm:h-full">

      <div class="plano_empresa">

        <div v-if="velocidadePromocional == 0">
          <h1 class="text-center text-7xl font-black tracking-tight text-enterprise-green-500">
            {{ velocidadePlano }}
          </h1>
          <div class="text-center mt-2 mb-4">
            <span class="bg-enterprise-green-800 text-white px-3 py-2 rounded-xl font-black text-2xl uppercase">mega</span>
          </div>
        </div>

        <div v-if="velocidadePromocional > 0">
          <p class="text-center text-sm text-gray-500 my-4">Compre</p>
          <h1 class="text-center text-4xl font-black tracking-tight text-main-pink-500">
            <span>{{ velocidadePlano }}</span>
            <span class="bg-enterprise-green-800 text-white px-2 py-1 rounded-xl font-black text-sm uppercase mega-promocional">mega</span>
          </h1>
          <p class="text-center text-sm text-gray-500 mt-2">Leve</p>
          <h1 class="text-center text-7xl font-black tracking-tight text-enterprise-green-500" v-if="velocidadePromocional == 0">
            {{ velocidadePlano }}
          </h1>
          <h1 class="text-center text-7xl font-black tracking-tight text-main-pink-500" v-else >
            {{ velocidadePromocional }}
          </h1>

          <div class="text-center mt-2 mb-4">
            <span class="bg-enterprise-green-800 text-white px-3 py-2 rounded-xl font-black text-2xl uppercase">mega</span>
          </div>
        </div>

      </div>

      <div class="rodape_plano">
        <div class="mb-3 p-3 text-main-purple-500 text-center font-black tracking-tighter" v-if="showSpeed">
          <span class="text-2xl">R$</span>
          <span class="text-5xl">{{ this.planMajor }}</span>
          <span class="text-2xl">,{{ this.planCents }}0</span>
        </div>
        <div class="content-center text-center my-3">
          <router-link
            class="mt-2 py-2 px-4 bg-enterprise-green-500 text-white text-lg font-extrabold uppercase rounded-xl shadow-md hover:bg-enterprise-green-800 focus:outline-none focus:ring-2 focus:bg-enterprise-green-800 focus:ring-opacity-75 bottom-4 relative"
            :to="{ name: 'Buy', params: { planId: idPlano } }" :id="'plan_' + this.idPlano" :name="'plan_' + this.idPlano"
          >
            Assine já!
          </router-link>
          <p class="text-sm font-semibold text-enterprise-green-800 tracking-tight leading-4">
            *Consulte condições
          </p>
        </div>        
      </div>

    </div>
    <!--fim box plano-->    
  </div>
  <div class="mb-8 sm:mb-0" v-else>
    <!--box plano especial-->
    
    <!--fim box plano-->

    <!--box plano-->
    <div class="border-main-pink-100 border-2 rounded-md sm:h-full shadow-md" :class="{'bg-main-purple-500':destacaPlano}">
      <div class=" ml-4 mt-6 mb-4">
        <span class= "text-white bg-main-purple-600 text-sm font-extrabold p-2 rounded-lg" :class="{'text-white':destacaPlano}">{{ tituloPlano }}</span>
      </div>
      <div class="mt-3">
        <span v-if="precoPromocional > 0" class="italic tracking-tighter text-main-purple-200 font-extrabold">Tempo Limitado</span>

        <div v-if="idPlano > 21">
          <h1 class="text-7xl font-black tracking-tight text-main-pink-500 ml-4">
            {{ velocidadePlano }} megas
          </h1>
        </div>

        <div v-if="idPlano <= 21"> 
          <h1 class="text-3xl md:text-3xl 2xl:text-5xl font-black tracking-tight text-main-pink-500 mb-1 ml-4" :class="{'text-white':destacaPlano}">
            <span>{{ velocidadePlano }} MEGA</span>
          </h1>
        </div>
        
        <div v-if="precoPromocional > 0">
          <div class="text-xl line-through ml-4 text-main-pink-500 font-extrabold">
            De <span class="line-through">R${{ this.planMajor }},90</span>
          </div>

          <div class="ml-4 text-main-purple-500 font-black tracking-tighter">
            <span class="text-2xl">R$ </span>
            <span class="text-8xl">{{ this.promoMajor }}</span>
            <span class="text-2xl">,{{ this.promoCents }}00</span>
          </div>

        </div>
        <div v-else class="text-main-purple-500 ml-4 my-2 font-black tracking-tighter" :class="{'text-white':destacaPlano}">
          <span class="text-3xl">R$</span>
          <span class="text-3xl">{{ this.planMajor }}</span>
          <span class="text-3xl" v-if="this.planCents > 0">,{{ this.planCents }}0</span>
          <span class="text-3xl" v-else>,00</span>
        </div>

        <!-- <div class="px-16">
          <hr class="my-4 border-2" />
        </div> -->
        <div class="w-full p-0 my-4">
          <img src="@/assets/img/exemplo_box.jpg" />
        </div>
        <div class="ml-4 mb-2 text-left text-md text-gray-600" :class="{'text-white':destacaPlano}">
          <div>
            <p>{{ subtituloPlano }}</p>
          </div>
        </div>

      </div>

      <div>
        <div class="mb-4">

          <div class="">
            <box-sva :svaPlano="svaPlano" :idPlano="idPlano" />
          </div>        

          <div class="mt-7 ml-4">
            <router-link
              class="my-4 mr-2 py-3 px-5 bg-main-pink-500 text-white text-xl font-extrabold rounded-lg shadow-md hover:bg-main-yellow-700 focus:outline-none focus:ring-2 focus:main-yellow-800 focus:ring-opacity-75 bottom-4"
              :to="{ name: 'Buy', params: { planId: idPlano } }" :id="'plan_' + this.idPlano" :name="'plan_' + this.idPlano"
            >
              Assine já!
            </router-link>
          </div>
          <p class="text-xs font-medium text-main-pink-500 tracking-tight leading-4 px-2 mt-8 mb-2 ml-2">
            {{ extrasPlano }}
          </p>

        </div>
      </div>

    </div>
    <!--fim box plano-->
  </div>
</template>
<style scoped>
.info-price {
  min-height:65px;
  display: flex;
    flex-direction: column;
    justify-content:end;
    align-items: center;
}
.btn-neon {
  border:3px solid #fc3a69;
  position: relative;
  cursor: pointer;
  box-shadow: inset 0 0 0.5em 0 #fc3a69, 0 0 0.5em 0 #fc3a69;
}
.txt-neon {
  cursor: pointer;
  text-shadow: 0 0 5px #FFF, 0 0 10px #FFF, 0 0 20px #fc3a69, 0 0 10px #fc3a69;
}
.btn-neon:hover {
  color: #f5f5f5;
  border:3px solid #f5f5f5;
  transition:0.4s;
}
.txt-neon:hover {
  color:white;
  transition:0.4s;
}

.velocidade_plano {
  @media (min-width: 768px) {
    min-height:140px;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
  }
}
.plano_empresa {
  @media (min-width: 768px) {
    min-height:135px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.mega-promocional {
  margin-left: -0.5rem;
}
.plano-destaque {
  position:relative;
  margin-top: -1rem;
  padding-left:1rem;
  padding-right:1rem;

}
.plano-destaque > span {
  position:absolute;
  top:50%;
  left:2.5rem;
  right:2.5rem;
  transform:translateY(-50%);

}
</style>
<script>
import BoxSva from '@/components/layout/plans/BoxSVA.vue';

export default {
  name: "BoxPlan",
  components: {BoxSva},
  data() {
    return {
      planMajor: this.getPlanValue()[0],
      planCents: this.getPlanValue()[1],
      promoMajor: this.getPromotionalValue()[0],
      promoCents: this.getPromotionalValue()[1],
      destacaPlano: this.idPlano == 18
    };
  },
  props: {
    tituloPlano: String,
    subtituloPlano: String,
    extrasPlano: String,
    velocidadePlano: Number,
    velocidadePromocional: Number,
    precoPlano: Number,
    precoPromocional: Number,
    svaPlano: Array,
    idPlano: Number,
    tipoPlano: Number
  },
  methods: {
    getPlanValue() {
      let preco = this.precoPlano;
      let digits = preco?.toString().split(".");
      return digits;
    },
    getPromotionalValue() {
      let promoPlano = this.precoPromocional;
      if (promoPlano == 0) { promoPlano == 0.00 }
      let digitsPromo = promoPlano?.toString().split(".");
      return digitsPromo;
    },
  },
  created() {
    this.getPlanValue();
  },
};
</script>
