<template>
    <Carousel v-bind="settings" :breakpoints="breakpoints">
      <Slide v-for="(l, key) in this.response.data" :key="key">
        <div class="carousel__item px-4">

          <!--box plano-->
          <box-plan
            :titulo-plano="l.title"
            :subtitulo-plano="l.subtitle"
            :velocidade-plano="l.speed"
            :velocidade-promocional="l.promotional_speed"
            :preco-plano="l.price"
            :preco-promocional="l.promotional_price"
            :sva-plano="l.sva"
            :id-plano="l.id"
            :tipo-plano="this.typeId"
            :extras-plano="l.extras"
          />
          <!--fim box plano-->            
        
        </div>
      </Slide>
  
      <template #addons>
        <Navigation />
      </template>
    </Carousel>

    
  </template>
  
  <script>
  import BoxPlan from "@/components/layout/plans/BoxPlan.vue";
  import LoadHome from "@/mixins/LoadHome";

  import 'vue3-carousel/dist/carousel.css'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

  export default {
    components: {
        BoxPlan,
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        urlBase: null,
        cityId: null,
        typeId: null,
            // carousel settings
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        // breakpoints are mobile first
        // any settings not specified will fallback to the carousel settings
        breakpoints: {
            // 700px and up
            700: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            // 1024 and up
            1024: {
                itemsToShow: 3,
                snapAlign: 'start',
            },
        },
    }),
    mixins: [LoadHome],
    methods: {
        getUrlBase() {
            this.cityId = this.idCidade;
            this.typeId = this.idTipo;
            this.urlBase = "plan/";
        },
    },
    props: {
        idCidade: Number,
        idTipo: Number,
    },
    created() {
        this.getUrlBase()
    },
  }
  </script>
  
  <style scoped>
  @import '@glidejs/glide/dist/css/glide.core.min.css';
  </style>